import React, { Component } from "react";
import Navbar from "./Navbar";

class About extends Component {
	render() {
		return (
			<div className='fullwidth'>
				<Navbar page='about'></Navbar>

				<div className='container-md'>
					<div className='row align-items-center'>
						<div></div>
						<div className='col-6'>
							<img
								src='http://www.picsum.photos/800'
								className='img-fluid'
								alt=''
							/>
						</div>
						<div className='col-6'>
							<div className='align-middle'>
								<h1>About Me</h1>
								<p className='text-start fs-5'>
									I'm a self-taught web developer located in Edinburgh,
									Scotland. I'm pursuing work in any facet of web development.
									I'm constantly working on improving my skills as a developer
									and I'm currently learning React to complete the MERN stack.
								</p>
								<p className='text-start fs-5'>
									Currently I work as an animal technician in a research
									environment at the University of Edinburgh.
								</p>
								<p className='text-start fs-5'>
									I was drawn to programming as I love learning and problem
									solving, and programming gives infinite opportunities for
									learning new things, and there are always problems to solve. I
									mostly enjoy web development because the things you make can
									be immediately shared all over the world.
								</p>
								<p className='text-start fs-5'>
									My main interests outside of development are music and board
									games and I'm part of a pub quiz team.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default About;

import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Navbar from "./Navbar";

const Contact = () => {
    const form = useRef();
    
	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				`${process.env.REACT_APP_SERVICE_ID}`,
				`${process.env.REACT_APP_TEMPLATE_ID}`,
				form.current,
                `${process.env.REACT_APP_PUBLIC_KEY}`
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
	};

	return (
		<div className='fullwidth'>
			<Navbar page='contact'></Navbar>
			<div className='section1'>
                <div className='container'>
                    <h1>Contact Me</h1>
                    <p>Feel free to get in touch if you'd like to discuss working together.</p>
					<div className='mb-3'>
						<form ref={form} onSubmit={sendEmail}>
							<label className='form-label'>Name</label>
							<input className='form-control' type='text' name='user_name' />
							<label className='form-label'>Email</label>
							<input className='form-control' type='email' name='user_email' />
							<label className='form-label'>Message</label>
							<textarea className='form-control' name='message' rows={5} />
							<input
								className='btn btn-primary mb-3'
								type='submit'
								value='Send'
							/>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;

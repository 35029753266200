import React, { Component } from "react";
import Navbar from "./Navbar";

class Home extends Component {
	render() {
		return (
			<div className='fullwidth'>
				<div className='section1'>
					<Navbar page='home'></Navbar>
					<div class='hero'>
						<div className='hero-overlay'>
							<row>
								<div className='col-6 hero-content'>
									<h1 className='title'>Colin Dick</h1>
									<h5 className='title'>Web Developer</h5>
								</div>
								<div className='col-6'></div>
							</row>
						</div>
					</div>
					<div className=''></div>
				</div>
				<div className='section2'>
					<div class='hero'>
						<div className='hero-overlay-pink'></div>
					</div>
				</div>
				<div className='section3'>
					<div class='hero'>
						<div className='hero-overlay-black'></div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;

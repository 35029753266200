import React, { Component } from "react";

class Footer extends Component {
	render() {
		return (
			<footer>
				<div className='container'>
					<div className='row'>
						<div className='col-4'>
							<h5>Construction</h5>
							<p>This site was built using React and Bootstrap.</p>
						</div>
						<div className='col-4'>
							<h5>Resources</h5>
							<p>
								The navbar effect was adapted from a CodePen by{" "}
								<a href='https://codepen.io/nerdcowboy/pen/zYqKmBJ'>
									Nerd Cowboy
								</a>
							</p>
							<p>
								Images shown on the About page are randomly fetched from{" "}
								<a href='https://www.picsum.photos/'>Picsum</a>
							</p>
							<p>
								Client side email technology provided by
								<a href='https://www.emailjs.com/'>EmailJS</a>
							</p>
						</div>
						<div className='col-4'>
							<h5>Links</h5>
							<div className='linksdiv'>
								<p>
									<a href='http://www.linkedin.com'>
										<i class='bi bi-linkedin'></i>
									</a>
								</p>
								<p>
									<a href='http://www.github.com'>
										<i class='bi bi-github'></i>
									</a>
								</p>
								<p>
									<a href='http://www.colindick.com'>
										<i class='bi bi-globe2'></i>
									</a>
								</p>
								<p>
									<a href='mailto:colindaviddick@gmail.com'>
										<i class='bi bi-envelope'></i>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;

import React, { Component } from "react";

class Navbar extends Component {
	render() {
		return (
			<nav class='navbar navbar-expand-lg navbar-dark' id="pageNavbar">
				<div class='container-fluid menu'>
					<button
						class='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarNavAltMarkup'
						aria-controls='navbarNavAltMarkup'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span class='navbar-toggler-icon'></span>
					</button>
					<div class='collapse navbar-collapse' id='navbarNavAltMarkup'>
						<div class='navbar-nav'>
							<a href='/' className={`nav-link ${this.props.page === "home" ? "active" : ""}`}>Home</a>
							<a href='/projects' className={`nav-link ${this.props.page === "projects" ? "active" : ""}`}>Projects</a>
							<a href='/learning' className={`nav-link ${this.props.page === "learning" ? "active" : ""}`}>Learning</a>
							<a href='/cv' className={`nav-link ${this.props.page === "cv" ? "active" : ""}`}>CV</a>
							<a href='/about' className={`nav-link ${this.props.page === "about" ? "active" : ""}`}>About Me</a>
							<a href='/contact' className={`nav-link ${this.props.page === "contact" ? "active" : ""}`}>Contact Me</a>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}

export default Navbar;

import React, { Component } from "react";
import Navbar from "./Navbar";

class Learning extends Component {
	render() {
		return (
			<div className='fullwidth'>
				<Navbar page='learning'></Navbar>
				<div className='section1'>
					<div className='container'>
						<h1>Learning</h1>
						<h4>History</h4>
						<p>
							I first touched on programming when I was very young, using BASIC
							on a Sinclair Spectrum, copying code from magazines that would
							create a line drawing or allow you to move a small square around
							the screen. I touched on programming again I was in college, this
							time using C, I was pretty sure that it wasn't for me, and lack of
							a particular project that I wanted to tackle just cemented that
							idea. Although I enjoyed the creative and problem solving side of
							it, memory management in particular just wasn't for me. I regret
							now that I didn't know at the time that there were different
							languages which had different strengths and weaknesses.
						</p>
						<p>
							It was almost 15 years later before I tried programming again.
							This time attempting to make a game using C# in Unity. I decided I
							needed a better grounding in programming and so took a course on
							Udemy, Denis Panjuta's C# Masterclass. I enjoyed it so much that I
							lost interest in actually making my game, and I just wanted to
							create things using C#. I created a number of console and desktop
							applications (using WPF). The xml files used with WPF are very
							similar to html and it made me want to try developing for the web,
							since it's far more accessible than desktop apps.
						</p>
						<p>
							And so I decided I'd switch again, this time to JavaScript. I took
							another bootcamp, this time Colt Steele's Web Development
							Bootcamp, and this is essentially where I am now. I completed this
							bootcamp and created my biggest project to date. ELM Gig Guide.
							It's made using almost all of what I'd learned during the
							bootcamp.
						</p>
						<hr />
						<h4>Now</h4>
						<p>
							At the moment I'm learning React to complete the MERN stack. I'm
							trying to use bootstrap for most of my projects and experiments at
							the moment just to get familiar with the classes. I'm making full
							stack sites at the moment for practice combining React with Node
							and Express, and I'm also trying to improve my designs.
						</p>

						<h4>Future</h4>
						<p>
							My plans for future learning include:
							<ul>
								<li>Possibly a UI/UX course</li>
								<li>adonis.js</li>
							</ul>
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default Learning;

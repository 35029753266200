import React, { Component } from "react";
import Navbar from "./Navbar";

class CV extends Component {
	render() {
		return (
			<div className='fullwidth'>
				<Navbar page='cv'></Navbar>
				<div className="section1">
					<div className='container container-cv'>
						<div className='row'>
							<div className='col-4 rightborder'>
								<h3 className='mb-4'>Education</h3>
								<div className='entry'>
									<h5>Udemy</h5>
									<p> Modern React Bootcamp</p>
									<p>The Web Developer Bootcamp</p>
									<p>C# Masterclass</p>
									<h5>Jewel & Esk Valley College</h5>
									<p>2001 – 2002 HNC Information Technology</p>
								</div>
								<div className='entry'>
									<h5>Stevenson College</h5>
									<p>NPA Animal Technology Years 1 & 2</p>
								</div>
								<div className='entry'>
									<h5>Jewel & Esk Valley College</h5>
									<p>2001 – 2002 HNC Information Technology</p>
								</div>
							</div>
							<div className='col-8'>
								<div className='cventry'>
									<div className='row'>
										<div className='col align-self-center'>
											{/* <img
											src='\images\UoELogo.png'
											className='logoimg'
											alt='University of Edinburgh logo'
										/> */}
											<h3 className='text-start'>
												The University of Edinburgh
											</h3>
											<h4 className='text-start'>Animal Technician</h4>
										</div>
										<div className='col datespace'>
											<h5 className='text-end text-muted date'>Since 2012</h5>
										</div>
									</div>
									<p className='cv-paragraph'>
										Responsible for maintaining breeding colonies and associated
										husbandry duties. Recording environmental data and
										monitoring animal welfare. Keeping the database up to date.
										Assisting researchers by performing licensed procedures.
										Frequent communication with Researchers about their stock
										and experiments.
									</p>
								</div>

								<div className='cventry'>
									<div className='row'>
										<div className='col align-self-center'>
											{/* <img
											src='\images\CRL_BIG.png'
											className='logoimg'
											alt='Charles River Laboratories logo'
										/> */}
											<h3 className='text-start'>Charles River Laboratories</h3>
											<h4 className='text-start'>Animal Technician</h4>
										</div>
										<div className='col datespace'>
											<h5 className='text-end text-muted date'>2002 - 2012</h5>
										</div>
									</div>
									<p className='cv-paragraph'>
										Responsible for running a variety of rodent studies.
										Recording data according to GLP. I learned every dosing and
										sampling technique that I was could in this position and
										often trained other members of staff in dosing and sampling
										techniques. I took part in planning and performing extremely
										time sensitive events such as day 1 bleeds, and study
										scheduling. I also spent a few months as an assistant in the
										surgery area, sedating rodents and monitoring their
										recovery, preparing the surgery with the necessary equipment
										and using aseptic techniques to keep the surgery and its
										equipment clean.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CV;

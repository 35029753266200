import { Routes, Route } from 'react-router-dom';
import About from './About';
import CV from './CV';
import Projects from './Projects';
import './App.css';
import Learning from './Learning';
import Home from './Home';
import Footer from './Footer';
import Contact from './Contact';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/projects' element={<Projects />}></Route>
        <Route path='/cv' element={<CV/>}></Route>
        <Route path='/learning' element={<Learning/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;

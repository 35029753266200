import React, { Component } from "react";
import Navbar from "./Navbar";

class Projects extends Component {
	render() {
		return (
			<div className='fullwidth'>
				<Navbar page='projects'></Navbar>
				<div className='section1'>
					<div className='container'>
						<h1>Projects</h1>
						<div className='projects'>
							{/* ELM Gig Guide */}
							<div className='card border-secondary'>
								<img src='/images/elm.png' className='card-img-top' alt='...' />
								<div className='card-body'>
									<div>
										<h5 className='card-title'>Elm Gig Guide</h5>
										<p className='card-text'>
											A fully functional gig listings page using MongoDB, Node
											and Express. It allows logged in users to
										</p>
									</div>
									<div className='buttonsdiv'>
										<a
											href='https://elmtestsite.herokuapp.com/'
											className='btn btn-primary'
										>
											Live Site
										</a>
										<a
											href='https://github.com/colindaviddick/elmgigsguide'
											className='btn btn-primary'
										>
											Github
										</a>
									</div>
								</div>
							</div>
							{/* Jungle */}
							<div className='card border-secondary'>
								<img
									src='/images/jungle.png'
									className='card-img-top'
									alt='...'
								/>
								<div className='card-body'>
									<div>
										<h5 className='card-title'>Jungle Design</h5>
										<p className='card-text'>
											A website mockup using React for routing and for
											controlling the navbar.
										</p>
									</div>
									<div className='buttonsdiv'>
										<a
											href='https://pfjungle.onrender.com/'
											className='btn btn-primary'
										>
											Live Site
										</a>
										<a
											href='https://github.com/colindaviddick/pfjungle'
											className='btn btn-primary'
										>
											Github
										</a>
									</div>
								</div>
							</div>
							{/* // Aesthetic */}
							<div className='card border-secondary'>
								<img
									src='/images/aesthetic.png'
									className='card-img-top'
									alt='...'
								/>
								<div className='card-body'>
									<div>
										<h5 className='card-title'>Aesthetic</h5>
										<p className='card-text'>
											A website mockup for a photographer. Made using react and
											Bootstrap
										</p>
									</div>
									<div className='buttonsdiv'>
										<a
											href='https://pfaesthetics.onrender.com/'
											className='btn btn-primary'
										>
											Live Site
										</a>
										<a
											href='https://github.com/colindaviddick/pfaesthetic'
											className='btn btn-primary'
										>
											Github
										</a>
									</div>
								</div>
							</div>

							{/* // Accentuate */}
							<div className='card border-secondary'>
								<img
									src='/images/accentuate.png'
									className='card-img-top'
									alt='...'
								/>
								<div className='card-body'>
									<div>
										<h5 className='card-title'>Accentuate</h5>
										<p className='card-text'>
											A social game made with React. Needs 3+ players. New
											information generated on every page load.
										</p>
									</div>
									<div className='buttonsdiv'>
										<a
											href='https://accentuate.onrender.com/'
											className='btn btn-primary'
										>
											Live Site
										</a>
										<a
											href='https://github.com/colindaviddick/accentuate'
											className='btn btn-primary'
										>
											Github
										</a>
									</div>
								</div>
							</div>
							{/* Colour Blocks */}
							<div className='card border-secondary'>
								<img
									src='/images/colorblocks.png'
									className='card-img-top'
									alt='...'
								/>
								<div className='card-body'>
									<div>
										<h5 className='card-title'>Colour Blocks</h5>
										<p className='card-text'>
											An experiment using state in React. Screen is covered in
											blocks which start white but mouseover triggers a random
											colour which fades to black.
										</p>
									</div>
									<div className='buttonsdiv'>
										<a
											href='https://colourblocks.onrender.com/'
											className='btn btn-primary'
										>
											Live Site
										</a>
										<a
											href='https://github.com/colindaviddick/state-colour-boxes'
											className='btn btn-primary'
										>
											Github
										</a>
									</div>
								</div>
							</div>
							{/* Snake Oil */}
							<div className='card border-secondary'>
								<img
									src='/images/snakeoil.png'
									className='card-img-top'
									alt='...'
								/>
								<div className='card-body'>
									<div>
										<h5 className='card-title'>Snake Oil Salesman</h5>
										<p className='card-text'>
											A utility page for a social game. Players are given roles
											and prompts to create a product, generated by this page.
											They then take it in turns to try to convince the buyer
											that their product is the best.
										</p>
									</div>
									<div className='buttonsdiv'>
										<a
											href='https://snakeoil.onrender.com'
											className='btn btn-primary'
										>
											Live Site
										</a>
										<a
											href='https://github.com/colindaviddick/snakeoil'
											className='btn btn-primary'
										>
											Github
										</a>
									</div>
								</div>
							</div>
							{/* Rhyme With Orange */}
							<div className='card border-secondary'>
								<img
									src='/images/orange.png'
									className='card-img-top'
									alt='...'
								/>
								<div className='card-body'>
									<div>
										<h5 className='card-title'>
											Things That Rhyme With Orange
										</h5>
										<p className='card-text'>
											Another single page board game. To be played in person,
											the page generates a series of topics and letters, and the
											players have to find words that fit with the prompts
											given.
										</p>
									</div>
									<div className='buttonsdiv'>
										<a
											href='https://orange-pnt7.onrender.com'
											className='btn btn-primary'
										>
											Live Site
										</a>
										<a
											href='https://github.com/colindaviddick/thingsthatrhymewithorange'
											className='btn btn-primary'
										>
											Github
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Projects;
